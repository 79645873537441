import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import { MoneyFormat } from '../../components/MoneyFormat';
import { ajaxGetQuotations } from '../../services/orderService';
import { ajaxGetQuotations as ajaxGetQuotationsProduction  } from '../../services/productionOrderService';
import { ajaxGetQuotations as ajaxGetQuotationsShipping } from '../../services/shippingOrderService';
import { ajaxGetDealersAutocomplete } from '../../services/dealerService';
import { toDateText, downloadFile, viewFile, isFloat} from '../../helpers';
import useWorkspace from '../../hooks/useWorkspace';
import LoadingSpinner from '../../components/LoadingSpinner';
import EnhancedTable from '../../components/EnhancedTable'
import Page from '../../components/Page';
import StyledSearchDate from '../../components/StyledSearchDate';
import inMemoryUser from '../../services/inMemoryUser';

const downloadQuotationFile = (id, fileType, fileName, fileExt) =>
  <div style={{ textAlign: 'center' }}>
    <IconButton
      size="small"
      sx={{ padding: 0 }}
      onClick={(e) =>{
        e.stopPropagation();
        viewFile({ params: { id, fileType}, fileName, fileExt })
      }}
    >
      <PictureAsPdfIcon fontSize="small"/>
    </IconButton>
    <IconButton
      size="small"
      sx={{ padding: 0 }}
      onClick={(e) =>{
        e.stopPropagation();
        downloadFile({ params: { id, fileType}, fileName, fileExt })
      }}
    >
      <FileDownloadIcon fontSize="small"/>
    </IconButton>
  </div>

const priceFormat = (v) => isFloat(v) ? <MoneyFormat currency="$" value={v}/> : '';

const dateFormat = (v) => v ? toDateText(v) : '-';

const columns = [
  {
    id: 'date',
    label: 'Quotation Date',
    minWidth: 100,
    format: dateFormat,
    sortable: true
  },
  {
    id: 'shipping_for_today',
    label: 'Shipping For Today',
    minWidth: 100,
    format: (value) => <div style={{ textAlign: 'center' }}><Checkbox size="small" sx={{ padding: 0 }} disabled {...(parseInt(value) > 0 ? { checked: true } : {})} /></div>,
    sortable: true
  },
  {
    id: 'quote_ref',
    label: 'Quotation Ref',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'quote_pdf',
    label: 'Quotation pdf',
    minWidth: 100,
    format: (v, row) => downloadQuotationFile(row.id, 'orders/quote_ref', row.quote_ref, 'pdf')
  },
  {
    id: 'shipping_status',
    label: 'Shipping Status',
    minWidth: 100,
    sortable: true,
    format: (value) =>
      <div style={{ textAlign: 'center' }}>
        <Chip
          sx={{color: value === 'done' ? '#000' : '#fff', height: '18px', fontSize: '12px'}}
          label={value}
          color={value === 'wait' ? 'error' : (value === 'ship' ? 'success' : (value === 'cancel' ? 'primary' : 'default'))}
          size="small"
        />
      </div>
  },
  {
    id: 'dealer_name',
    label: 'Company',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'shipping_date_expected',
    label: 'Shipping Date Expected',
    minWidth: 100,
    format: dateFormat,
    sortable: true
  },
  {
    id: 'note',
    label: 'Note',
    minWidth: 100,
    sortable: true,
    format: (v) => <div style={{ whiteSpace: 'pre-line' }}>{v}</div>
  },
  {
    id: 'value',
    label: 'Value',
    minWidth: 100,
    format: priceFormat,
    sortable: true,
    summary: (item, column) => (item.shipping_status !== 'cancel' ? (item[column.id] || 0) : 0)
  },
  {
    id: 'shipping_cost',
    label: 'Shipping Cost',
    minWidth: 100,
    format: priceFormat,
    summary: (item, column) => (item.shipping_status !== 'cancel' ? (item[column.id] || 0) : 0)
  },
  {
    id: 'actual_shipping_cost',
    label: 'Actual Shipping Cost',
    minWidth: 100,
    format: priceFormat,
    sortable: true,
    summary: (item, column) => (item.shipping_status !== 'cancel' ? (item[column.id] || 0) : 0)
  },
  {
    id: 'sale',
    label: 'Sale',
    minWidth: 100,
    format: priceFormat,
    sortable: true,
    summary: (item, column) => (item.shipping_status !== 'cancel' ? (item[column.id] || 0) : 0)
  },
  {
    id: 'shipping_invoice_pdf',
    label: 'Shipping Invoice pdf',
    minWidth: 100,
    format: (v, row) => v && downloadQuotationFile(row.id, 'quotation/shipping_invoice_pdf', row.oc_ref + '-shipping-invoice', 'pdf')
  },
  {
    id: 'shipping_packing_list_pdf',
    label: 'Shipping Packing List pdf',
    minWidth: 100,
    format: (v, row) => v && downloadQuotationFile(row.id, 'quotation/shipping_packing_list_pdf', row.oc_ref + '-packing-list', 'pdf')
  },
  {
    id: 'shipping_date',
    label: 'Shipping Date',
    minWidth: 100,
    format: dateFormat,
    sortable: true
  },
  { id: 'shipping_awb',
    label: 'Shipping AWB',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'shipping_weight',
    label: 'Shipping Weight',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'payment_status',
    label: 'Payment Status',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'payment_due_date',
    label: 'Payment Due Date',
    minWidth: 100,
    format: dateFormat,
    sortable: true
  },
  {
    id: 'payment_date',
    label: 'Payment Date',
    minWidth: 100,
    format: dateFormat,
    sortable: true
  },
  {
    id: 'payment_advance',
    label: 'Payment Advance',
    minWidth: 100,
    format: priceFormat,
    sortable: true,
    summary: (item, column) => (item.shipping_status !== 'cancel' ? (item[column.id] || 0) : 0)
  },
  {
    id: 'payment_full',
    label: 'Payment Full',
    minWidth: 100,
    format: priceFormat,
    sortable: true,
    summary: (item, column) => (item.shipping_status !== 'cancel' ? (item[column.id] || 0) : 0)
  },
  {
    id: 'payment_received',
    label: 'Payment Received',
    minWidth: 100,
    format: priceFormat,
    sortable: true,
    summary: (item, column) => (item.shipping_status !== 'cancel' ? (item[column.id] || 0) : 0)
  },
  {
    id: 'outstanding',
    label: 'Outstanding',
    minWidth: 100,
    format: priceFormat,
    sortable: true,
    summary: (item, column) => (item.shipping_status !== 'cancel' ? (item[column.id] || 0) : 0)
  },
  {
    id: 'outstanding_shipped',
    label: 'Outstanding Shipped',
    minWidth: 100,
    format: priceFormat,
    sortable: true,
    summary: (item, column) => (item.shipping_status !== 'cancel' ? (item[column.id] || 0) : 0)
  }
];


export default function QuotationList() {
  const [workspace, setWorkspace] = useWorkspace(true, 'quotationPage', { table :{}, filter: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState(workspace.filter.searchTerm || '');
  const [startDate, setStartDate] = useState(workspace.filter.startDate || null);
  const [endDate, setEndDate] = useState(workspace.filter.endDate || null);
  const [autocomplete, setAutocomplete] = useState(workspace.filter.autocomplete || null);
  const [autocompleteList, setAutocompleteList] = useState([]);

  const navigate = useNavigate();

  const onTableParams = useCallback((params) => {
    if ( isLoading ) {
      return;
    }
    const {pageItems, ...restParams} = params;
    setWorkspace(prev => ({
      ...prev,
      table: {
        ...prev.table,
        ...restParams
      }
    }));
  }, [isLoading, setWorkspace]);

  useEffect(() => {
    if ( isLoading ) {
      return;
    }
    setWorkspace(prev => ({
      ...prev,
      filter: {
        ...prev.filter,
        searchTerm, startDate, endDate, autocomplete
      }
    }));
  }, [
    isLoading, setWorkspace,
    searchTerm, startDate, endDate, autocomplete
  ]);

  useEffect(() => {
    let getQuotations = ajaxGetQuotations;
    if(inMemoryUser.isShipping()) {
      getQuotations = ajaxGetQuotationsShipping;
    } else if(inMemoryUser.isProduction()) {
      getQuotations = ajaxGetQuotationsProduction;
    }

    getQuotations({})
      .then((res) => {
        setItems(res.data);
      })
      .then(() => {
        return ajaxGetDealersAutocomplete().then((dealerRes) => {
          const dealerList = dealerRes.data;
            const autocompleteList = [
              { id: -1, name: 'All Companies' },
              ...dealerList.map(d => ({ id: d.id, name: d.name }))
            ]
            setAutocompleteList(autocompleteList);
            if ( !autocomplete || !autocompleteList.find(d => d.id === autocomplete.id) ) {
              setAutocomplete(autocompleteList.find(d => d.id === -1));
            }
            setIsLoading(false);
      }).catch(() => {
          setAutocomplete(null);
        setIsLoading(false);
      });
      })
      .catch(() => {
        setItems([]);
        setIsLoading(false);
      });
  }, [autocomplete]);

  const onClearFilter = () => {
    setSearchTerm('');
    setStartDate(null);
    setEndDate(null);
    setAutocomplete(autocompleteList.find(d => d.id === -1));
  };

  const handleDealerNameSearch = (event, value) => {
    setAutocomplete(value);
  };

  const searchChangeHandle = (event) => {
    const new_value = event.target.value.toLowerCase();
    setSearchTerm(new_value);
  };

  useEffect(() => {
    const results = items.filter((item) => {
      let testStartDate = true;
      let testEndDate = true;
      let searchByTerm = true
      let testDealer = true;

      if(searchTerm) {
        const fields = ['quote_ref', 'note', 'shipping_awb']
        searchByTerm = fields.reduce((acc, field) => acc || (item[field] && item[field].toLowerCase().includes(searchTerm)), false); 
      }

      if (startDate) {
        testStartDate = item.date >= startDate;
      }
      if (endDate) {
        testEndDate = item.date <= endDate;
      }

      if (autocomplete && autocomplete.id !== -1) {
        testDealer = item.dealer_name === autocomplete.name;
      }
      
      return testStartDate && testEndDate && searchByTerm && testDealer;
    });

    setSearchResults(results);
  }, [searchTerm, items, startDate, endDate, autocomplete]);

  return (
    <Page title="Quotations" maxWidth={false} sx={{ display: 'flex', flexDirection: 'column' }}>
      {
        isLoading ?
        <LoadingSpinner />
        :
        <React.Fragment>
          <Box>
            <Grid container spacing={1}>
              <Grid xs={12} sm={4} item>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  label="Search"
                  placeholder="Search keyword"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  onChange={searchChangeHandle}
                  value={searchTerm}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid xs={12} sm={4} item>
                <Autocomplete
                  disablePortal
                  sx={{ width: '100%' }}
                  size="small"
                  value={autocomplete}
                  options={autocompleteList}
                  isOptionEqualToValue={(option, value) => (value && option.id === value.id)}
                  getOptionLabel={(option) => `${option.name} [${option.id}]`}
                  onChange={handleDealerNameSearch}
                  renderInput={(params) => <TextField {...params} label="Dealer Name" />}
                />
              </Grid>
              <Grid xs={12} sm={4} item>
                <StyledSearchDate
                  startDate={startDate}
                  endDate={endDate}
                  onStartDate={setStartDate}
                  onEndDate={setEndDate}
                  gridProps={{ columnSpacing: 1 }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                {
                  !inMemoryUser.isShipping() &&
                  <Button
                    size="small"
                    variant="contained"
                    color="info"
                    sx={{ margin: '10px 0' }}
                    startIcon={<AddIcon />}
                    onClick={() => navigate('/quotations/add')}
                  >
                    New quotation
                  </Button>
                }
              </Box>
              <Box>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ margin: '10px 0' }}
                  startIcon={<FilterListOffIcon />}
                  onClick={onClearFilter}
                >
                  Clear Filter
                </Button>
              </Box>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, position: 'relative' }}>
            <Box sx={{ position: 'absolute', inset: '0px 0px 10px 0px' }}>
              <EnhancedTable
                border
                fullHeight
                stickyColumn
                fontSize="12px"
                columns={columns}
                items={searchResults}
                onRowClick={(id) => navigate('/quotations/edit/' + id)}
                rowProps={(row) => (
                  row.request_to_delete ?
                  { className: 'request_to_delete', sx: { "&.request_to_delete td": { backgroundColor: '#ffeacb!important' } } }
                  :
                  row.shipping_status === 'cancel' ?
                  { className: 'shipping_status_cancel', sx: { "&.shipping_status_cancel td": { backgroundColor: '#ffb4b4!important' } } }
                  :
                  {}
                )}
                order={workspace.table.order || 'desc'}
                orderBy={workspace.table.orderBy || 'date'}
                page={workspace.table.pageIndex || 0}
                rowsPerPage={workspace.table.rowsPerPage || 50}
                onTableParams={onTableParams}
              />
            </Box>
          </Box>
        </React.Fragment>
      }
    </Page>
  );
}
