import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ajaxGetOrders } from '../services/productionOrderService';
import { ajaxGetDealersAutocomplete } from '../services/dealerService';
import { toDateText, downloadFile, viewFile, isFloat} from '../helpers';
import useWorkspace from '../hooks/useWorkspace';
import LoadingSpinner from '../components/LoadingSpinner';
import Page from '../components/Page';
import Box from '@mui/material/Box';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import EnhancedTable from '../components/EnhancedTable'
import Grid from '@mui/material/Grid';
import StyledSearchDate from '../components/StyledSearchDate';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import { MoneyFormat } from '../components/MoneyFormat';
import moment from 'moment';

const downloadOrderFile = (id, fileType, fileName, fileExt) =>
  <div style={{ textAlign: 'center' }}>
    <IconButton
      size="small"
      sx={{ padding: 0 }}
      onClick={(e) =>{
        e.stopPropagation();
        viewFile({ params: { id, fileType}, fileName, fileExt })
      }}
    >
      <PictureAsPdfIcon />
    </IconButton>
    <IconButton
      size="small"
      sx={{ padding: 0 }}
      onClick={(e) =>{
        e.stopPropagation();
        downloadFile({ params: { id, fileType}, fileName, fileExt })
      }}
    >
      <FileDownloadIcon fontSize="small"/>
    </IconButton>
  </div>

const dateFormat = (v) => v ? toDateText(v) : '-';
const priceFormat = (v) => isFloat(v) ? <MoneyFormat currency="$" value={v}/> : '';

const columns = [
  {
    id: 'date',
    label: 'Order Date',
    minWidth: 100,
    format: dateFormat,
    sortable: true
  },
  {
    id: 'shipping_for_today',
    label: 'Shipping For Today',
    minWidth: 100,
    format: (value) => <div style={{ textAlign: 'center' }}><Checkbox size="small" sx={{ padding: 0 }} disabled {...(parseInt(value) > 0 ? { checked: true } : {})} /></div>,
    sortable: true
  },
  {
    id: 'oc_ref',
    label: 'OC Ref',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'shipping_status',
    label: 'Shipping Status',
    minWidth: 100,
    sortable: true,
    format: (value) =>
      <div style={{ textAlign: 'center' }}>
        <Chip
          sx={{color: value === 'done' ? '#000' : '#fff', height: '18px', fontSize: '12px'}}
          label={value}
          color={value === 'wait' ? 'error' : (value === 'ship' ? 'success' : (value === 'cancel' ? 'primary' : 'default'))}
          size="small"
        />
      </div>
  },
  {
    id: 'oc_pdf',
    label: 'OC pdf',
    minWidth: 100,
    format: (_, row) => downloadOrderFile(row.id, 'orders/oc_pdf', row.oc_ref, 'pdf')
  },
  {
    id: 'invoice',
    label: 'Invoice',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'invoice_pdf',
    label: 'Invoice pdf',
    minWidth: 100,
    format: (_, row) => downloadOrderFile(row.id, 'orders/invoice_pdf', row.oc_ref + '-' + (row.invoice ? row.invoice : 'invoice'), 'pdf')
  },
  {
    id: 'dealer_name',
    label: 'Company',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'shipping_date_expected',
    label: 'Shipping Date Expected',
    minWidth: 100,
    format: dateFormat,
    sortable: true
  },
  {
    id: 'note',
    label: 'Note',
    minWidth: 100,
    sortable: true,
    format: (v) => <div style={{ whiteSpace: 'pre-line' }}>{v}</div>
  },
  {
    id: 'shipping_cost',
    label: 'Shipping Cost',
    minWidth: 100,
    format: priceFormat,
    sortable: true,
    summary: true
  },
  {
    id: 'actual_shipping_cost',
    label: 'Actual Shipping Cost',
    minWidth: 100,
    format: priceFormat,
    sortable: true,
    summary: true
  },
  {
    id: 'shipping_invoice_pdf',
    label: 'Shipping Invoice pdf',
    minWidth: 100,
    format: (v, row) => v && downloadOrderFile(row.id, 'orders/shipping_invoice_pdf', row.oc_ref + '-shipping-invoice', 'pdf')
  },
  {
    id: 'shipping_packing_list_pdf',
    label: 'Shipping Packing List pdf',
    minWidth: 100,
    format: (v, row) => v && downloadOrderFile(row.id, 'orders/shipping_packing_list_pdf', row.oc_ref + '-packing-list', 'pdf')
  },
  {
    id: 'shipping_date',
    label: 'Shipping Date',
    minWidth: 100,
    format: dateFormat,
    sortable: true
  },
  { id: 'shipping_awb',
    label: 'Shipping AWB',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'shipping_weight',
    label: 'Shipping Weight',
    minWidth: 100,
    sortable: true
  },
  {
    id: 'payment_status',
    label: 'Payment Status',
    minWidth: 100,
    sortable: true
  },
];


export default function ProductionOrderList() {
  const [workspace, setWorkspace] = useWorkspace(true, 'productionOrderPage', { table :{}, filter: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState(workspace.filter.searchTerm || '');
  const [startDate, setStartDate] = useState(workspace.filter.startDate || null);
  const [endDate, setEndDate] = useState(workspace.filter.endDate || null);
  const [forTodayOnly, setForTodayOnly] = useState(workspace.filter.forTodayOnly || false);
  const [expectedShipBeforeToday, setExpectedShipBeforeToday] = useState(workspace.filter.expectedShipBeforeToday || false);
  const [exceptShippingStatus, setExceptShippingStatus] = useState(workspace.filter.exceptShippingStatus || '');
  const [autocomplete, setAutocomplete] = useState(workspace.filter.autocomplete || null);
  const [autocompleteList, setAutocompleteList] = useState([]);

  const navigate = useNavigate();

  const onTableParams = useCallback((params) => {
    if ( isLoading ) {
      return;
    }
    const {pageItems, ...restParams} = params;
    setWorkspace(prev => ({
      ...prev,
      table: {
        ...prev.table,
        ...restParams
      }
    }));
  }, [isLoading, setWorkspace]);

  useEffect(() => {
    if ( isLoading ) {
      return;
    }
    setWorkspace(prev => ({
      ...prev,
      filter: {
        ...prev.filter,
        searchTerm, startDate, endDate,
        forTodayOnly, expectedShipBeforeToday,
        exceptShippingStatus, autocomplete
      }
    }));
  }, [
    isLoading, setWorkspace,
    searchTerm, startDate, endDate,
    forTodayOnly, expectedShipBeforeToday,
    exceptShippingStatus, autocomplete
  ]);

  useEffect(() => {
    ajaxGetOrders({})
      .then((res) => {
        setItems(res.data);
      })
      .then(() => {
        return ajaxGetDealersAutocomplete().then((dealerRes) => {
          const dealerList = dealerRes.data;
            const autocompleteList = [
              { id: -1, name: 'All Companies' },
              ...dealerList.map(d => ({ id: d.id, name: d.name }))
            ]
            setAutocompleteList(autocompleteList);
            if ( !autocomplete || !autocompleteList.find(d => d.id === autocomplete.id) ) {
              setAutocomplete(autocompleteList.find(d => d.id === -1));
            }
            setIsLoading(false);
      }).catch(() => {
          setAutocomplete(null);
        setIsLoading(false);
      });
      })
      .catch(() => {
        setItems([]);
        setIsLoading(false);
      });
  }, [autocomplete]);

  const onClearFilter = () => {
    setSearchTerm('');
    setStartDate(null);
    setEndDate(null);
    setForTodayOnly(false);
    setExpectedShipBeforeToday(false);
    setExceptShippingStatus('');
    setAutocomplete(autocompleteList.find(d => d.id === -1));
  };

  const handleDealerNameSearch = (event, value) => {
    setAutocomplete(value);
  };

  const searchChangeHandle = (event) => {
    const new_value = event.target.value.toLowerCase();
    setSearchTerm(new_value);
  };

  const onShippingStatus = (checked, value) => {
    let exceptShippingStatusList = exceptShippingStatus.split(';').filter(s => s !== value);
    if ( !checked ) {
      exceptShippingStatusList.push(value);
    }
    setExceptShippingStatus(exceptShippingStatusList.join(';'));
  };

  useEffect(() => {
    const results = items.filter((item) => {
      let testStartDate = true;
      let testEndDate = true;
      let testShippingForToday = true;
      let testExpectedShipBeforeToday = true;
      let searchByTerm = true
      let testShippingStatus = true;
      let testDealer = true;

      if(searchTerm) {
        const fields = ['oc_ref', 'invoice', 'note', 'shipping_awb']
        searchByTerm = fields.reduce((acc, field) => acc || (item[field] && item[field].toLowerCase().includes(searchTerm)), false); 
      }

      if (startDate) {
        testStartDate = item.date >= startDate;
      }
      if (endDate) {
        testEndDate = item.date <= endDate;
      }

      if (forTodayOnly) {
        testShippingForToday = item.shipping_for_today === 1;
      }

      if (expectedShipBeforeToday) {
        const today = moment().hour(0).minute(0).second(0).millisecond(0).unix();
        if ( item.shipping_date_expected === '' ) {
          testExpectedShipBeforeToday = false;
        } else {
          testExpectedShipBeforeToday = item.shipping_date_expected < today;
        }
      }

      testShippingStatus = (exceptShippingStatus.indexOf(item.shipping_status) === -1);

      if(autocomplete && autocomplete.id !== -1) {
        testDealer = item.dealer_name === autocomplete.name;
      }

      return testStartDate && testEndDate && testShippingForToday && searchByTerm && testShippingStatus && testDealer && testExpectedShipBeforeToday;
    });

    setSearchResults(results);
  }, [searchTerm, items, startDate, endDate, forTodayOnly, expectedShipBeforeToday, exceptShippingStatus, autocomplete]);

  return (
    <Page title="Production Orders" maxWidth={false} sx={{ display: 'flex', flexDirection: 'column' }}>
      {
        isLoading ?
        <LoadingSpinner />
        :
        <React.Fragment>
          <Box sx={{ mb: '5px' }}>
            <Grid container spacing={2}>
              <Grid xs={12} sm={4} item>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  label="Search"
                  placeholder="Search keyword"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  onChange={searchChangeHandle}
                  value={searchTerm}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid xs={12} sm={4} item>
                <Autocomplete
                  disablePortal
                  sx={{ width: '100%' }}
                  size="small"
                  value={autocomplete}
                  options={autocompleteList}
                  isOptionEqualToValue={(option, value) => (value && option.id === value.id)}
                  getOptionLabel={(option) => `${option.name} [${option.id}]`}
                  onChange={handleDealerNameSearch}
                  renderInput={(params) => <TextField {...params} label="Dealer Name" />}
                />
              </Grid>
              <Grid xs={12} sm={4} item>
                <StyledSearchDate startDate={startDate} endDate={endDate} onStartDate={setStartDate} onEndDate={setEndDate} />
              </Grid>
              <Grid xs={12} sm={4} item>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={forTodayOnly} onChange={(e)=>setForTodayOnly(e.target.checked)}/>}
                    label="Shipments for today"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={expectedShipBeforeToday}
                        onChange={(e)=>setExpectedShipBeforeToday(e.target.checked)}
                        size="small"
                      />
                    }
                    label="Expected Shipping Date is before Today"
                  />
                </FormGroup>
              </Grid>
              <Grid xs={12} sm={4} item>
                <FormControl sx={{ mt: '10px' }}>
                  <FormLabel id="shipping-status-label">Shipping status</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      value="wait"
                      label="Wait"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          checked={exceptShippingStatus.indexOf('wait') === -1}
                          onChange={(e) => onShippingStatus(e.target.checked, 'wait')}
                          size="small"
                        />
                      }
                    />
                    <FormControlLabel
                      value="ship"
                      label="Ship"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          checked={exceptShippingStatus.indexOf('ship') === -1}
                          onChange={(e) => onShippingStatus(e.target.checked, 'ship')}
                          size="small"
                        />
                      }
                    />
                    <FormControlLabel
                      value="done"
                      label="Done"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          checked={exceptShippingStatus.indexOf('done') === -1}
                          onChange={(e) => onShippingStatus(e.target.checked, 'done')}
                          size="small"
                        />
                      }
                    />
                    <FormControlLabel
                      value="cancel"
                      label="Cancel"
                      labelPlacement="end"
                      control={
                        <Checkbox
                          checked={exceptShippingStatus.indexOf('cancel') === -1}
                          onChange={(e) => onShippingStatus(e.target.checked, 'cancel')}
                          size="small"
                        />
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid xs={12} sm={4} item>
                <Box sx={{ height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
                  <Box>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{ margin: '10px 0' }}
                      startIcon={<FilterListOffIcon />}
                      onClick={onClearFilter}
                    >
                      Clear Filter
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1, position: 'relative' }}>
            <Box sx={{ position: 'absolute', inset: '0px 0px 10px 0px' }}>
              <EnhancedTable
                border
                fullHeight
                stickyColumn
                fontSize="12px"
                columns={columns}
                items={searchResults}
                onRowClick={(id) => navigate('/orders/edit/' + id)}
                order={workspace.table.order || 'desc'}
                orderBy={workspace.table.orderBy || 'date'}
                page={workspace.table.pageIndex || 0}
                rowsPerPage={workspace.table.rowsPerPage || 50}
                onTableParams={onTableParams}
              />
            </Box>
          </Box>
        </React.Fragment>
      }
    </Page>
  );
}
